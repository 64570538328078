@font-face {
    font-family: ArialMT;
    src: url(./archives/ArialMT.ttf);
}

@font-face {
    font-family: ArialMTLight;
    src: url(./archives/ArialMT-Light.TTF);
}

@font-face {
    font-family: ArialMTBold;
    src: url(./archives/ArialMT-Bold.OTF);
}
