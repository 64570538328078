
/* BB Textos */
@font-face {
    font-family: 'BancoDoBrasilTextos';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/BancoDoBrasilTextos-Regular.ttf');
}

@font-face {
    font-family: 'BancoDoBrasilTextos';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/BancoDoBrasilTextos-Bold.ttf');
}

@font-face {
    font-family: 'BancoDoBrasilTextos';
    font-weight: lighter;
    font-style: normal;
    src: url('../fonts/BancoDoBrasilTextos-Light.ttf');
}

@font-face {
    font-family: 'BancoDoBrasilTextos';
    font-weight: medium;
    font-style: normal;
    src: url('../fonts/BancoDoBrasilTextos-Medium.ttf');
}

@font-face {
    font-family: 'BancoDoBrasilTextos';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/BancoDoBrasilTextos-XBold.ttf');
}


/* BB Titulos */

@font-face {
    font-family: "BancoDoBrasilTitulos";
    font-weight: normal;
    font-style: normal;
    src: url("../fonts/BancoDoBrasilTitulos-Regular.ttf");
}

@font-face {
    font-family: "BancoDoBrasilTitulos";
    font-weight: bold;
    font-style: normal;
    src: url("../fonts/BancoDoBrasilTitulos-Bold.ttf");
}

@font-face {
    font-family: "BancoDoBrasilTitulos";
    font-weight: lighter;
    font-style: normal;
    src: url("../fonts/BancoDoBrasilTitulos-Light.ttf");
}

@font-face {
    font-family: "BancoDoBrasilTitulos";
    font-weight: medium;
    font-style: normal;
    src: url("../fonts/BancoDoBrasilTitulos-Medium.ttf");
}

@font-face {
    font-family: "BancoDoBrasilTitulos";
    font-weight: 900;
    font-style: normal;
    src: url("../fonts/BancoDoBrasilTitulos-XBold.ttf");
}

/* Inter */

@font-face {
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Inter';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: 'Inter';
    font-weight: lighter;
    font-style: normal;
    src: url('../fonts/Inter-Light.ttf');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/Inter-ExtraBold.ttf');
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'BancoDoBrasilTextos' !important;
    scroll-behavior: smooth;
    background-color: #fff;
}
